import type { positions, assets } from "../data.json";
export const IData = "IData";
export type ElementType<T> = T extends Array<infer U> ? U : never;

export interface IData {
  positions: typeof positions;
  assets: typeof assets;
}
export type IDataPositionRecord = ElementType<IData["positions"]>;

export interface IDataSourceItem {
  symbol: string;
  market: string;
  market_price: number;
  quantity: number;
  market_value: number;
  unrealized_pnl: number;
  average_cost: number;
  percent: string;
}
