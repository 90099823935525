import { IDataStateInstanceType } from '../types';
import { createStore } from 'zustand/vanilla';

function dataStore(set: (obj: Partial<IDataState>) => void, get: () => IDataState) {
  return {
    data: {} as IDataStateInstanceType,
    setState(key: string, data: unknown) {
      set({
        data: {
          ...get().data,
          [key]: data,
        },
      });
    },
  };
}
export const createDataStore = () => createStore<IDataState>(dataStore);
export type IDataState = ReturnType<typeof dataStore>;
export type IDataStore = ReturnType<typeof createDataStore>;
export const IDataStore = 'IDataStore';
