/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { changePercent, hkToUS, symbolMap } from "./util";
import { IData, IDataPositionRecord, IDataSourceItem } from "../../../types";
import { Assets } from "./Assets";
import { inject, injectable } from "inversify";
import { IAssets } from "../../../.frame/types";
import { Tag } from "antd";

// ---------------------- 原始头寸
export const position_prices = {
  BILI: 16.15,
  "01797": 18.7,
  BABA: 88.31,
  "01810": 19.36,
  JD: 34.65,
  TIGR: 4.43,
  LI: 21.71,
  BIDU: 108.77,
  BEKE: 18.9988,
  TSLA: 175.03,
  ZH: 4,
  QTTOY: 0.011,
  GOTU: 6.58,
} as Record<string, number>;

// ----------- 十月三日头寸
const field_position_prices_1003 = {
  BILI: 30.1699,
  // 东方
  "01797": 25.5,
  BABA: 115.25,
  // 小米
  "01810": 24.2,
  JD: 45.14,
  TIGR: 8.31,
  LI: 30.05,
  BIDU: 115.593,
  ZH: 4.18,
  GOTU: 4.0993,
  // ----- 无
  BEKE: 0.0001,
  TSLA: 0.0001,
  QTTOY: 0.011,
} as Record<string, number>;

@injectable()
export class Stocks {
  private data: IData;
  private assets: Assets;
  constructor(@inject(IData) data: IData, @inject(IAssets) assets: Assets) {
    this.data = data;
    this.assets = assets;
  }

  getFields() {
    const totalMarketValue = this.assets.getTotalMarketValue();
    // ------ 统一
    const recordData = (record: IDataPositionRecord) => {
      const symbol =
        symbolMap[record.contract.symbol] || record.contract.symbol;
      const market_value =
        record.contract.market === "HK"
          ? hkToUS(record.market_value)
          : record.market_value;

      const position_price = position_prices[record.contract.symbol] || 0;
      const field_position_price_1003 =
        field_position_prices_1003[record.contract.symbol];
      // -----------
      return {
        symbol,
        market_value,
        position_price,
        field_position_price_1003,
        unrealized_pnl:
          record.contract.market === "HK"
            ? hkToUS(record.unrealized_pnl)
            : record.unrealized_pnl,
      };
    };

    const recordValue = (id: string) => (record: IDataPositionRecord) => {
      return recordData(record)[id];
    };

    return [
      {
        id: "symbol",
        title: "股票名称",
        value: recordValue("symbol"),
      },
      {
        id: "market",
        title: "股票类别",
        value(record: IDataPositionRecord) {
          return record.contract.market;
        },
      },
      {
        id: "market_price",
        title: "单价",
        value(record: IDataPositionRecord) {
          return record.market_price;
        },
      },
      {
        id: "quantity",
        title: "数量",
        value(record: IDataPositionRecord) {
          return record.quantity;
        },
      },
      {
        id: "market_value",
        title: "美金市值",
        value: recordValue("market_value"),
      },
      {
        id: "unrealized_pnl",
        title: "盈亏",
        value: recordValue("unrealized_pnl"),
      },
      {
        id: "average_cost",
        title: "平均成本",
        value(record: IDataPositionRecord) {
          return record.average_cost;
        },
      },
      {
        id: "percent",
        title: <Tag color="red">占比</Tag>,
        value(record: IDataPositionRecord) {
          return `${(
            (recordData(record).market_value * 100) /
            totalMarketValue
          ).toFixed(1)}%`;
        },
      },
      {
        id: "position_price",
        title: "原始建仓",
        value(record: IDataPositionRecord) {
          return recordData(record).position_price;
        },
      },
      {
        title: "原始建仓变化",
        id: "position_price_rate",
        value(record: IDataPositionRecord) {
          const position_price = recordData(record).position_price;
          return `${(
            ((record.market_price - position_price) * 100) /
            position_price
          ).toFixed(1)}%`;
        },
      },
      {
        title: "建仓1003",
        id: "field_position_price_1003",
        value: recordValue("field_position_price_1003"),
      },
      {
        title: "建仓1003-变化",
        id: "position_prices_1003_change",
        value(record: IDataPositionRecord) {
          const { field_position_price_1003, symbol } = recordData(record);
          return (
            (
              ((record.market_price - field_position_price_1003) /
                field_position_price_1003) *
              100
            ).toFixed(2) + "%"
          );
        },
      },
      {
        title: "建仓1003-下跌10%点位",
        id: "position_prices_1003_down_10",
        value(record: IDataPositionRecord) {
          return changePercent(field_position_prices_1003, 0.9)[
            record.contract.symbol
          ];
        },
      },
      {
        title: "建仓1003-下跌20%点位",
        id: "position_prices_1003_down_20",
        value(record: IDataPositionRecord) {
          return changePercent(field_position_prices_1003, 0.8)[
            record.contract.symbol
          ];
        },
      },
      {
        title: "建仓1003-下跌30%点位",
        id: "position_prices_1003_down_30",
        value(record: IDataPositionRecord) {
          return changePercent(field_position_prices_1003, 0.7)[
            record.contract.symbol
          ];
        },
      },
      {
        title: "建仓1003-下跌40%点位",
        id: "position_prices_1003_down_40",
        value(record: IDataPositionRecord) {
          return changePercent(field_position_prices_1003, 0.6)[
            record.contract.symbol
          ];
        },
      },
    ];
  }
  getColumns() {
    return this.getFields().map((item) => {
      return {
        title: item.title,
        dataIndex: item.id,
      };
    });
  }
  getDataSource(): IDataSourceItem[] {
    return this.data.positions
      .map((item) => {
        const record = {} as IDataSourceItem;
        this.getFields().forEach((item2) => {
          record[item2.id] = item2.value(item);
        });
        return record;
      })
      .filter((item) => item.symbol !== "QTTOY")
      .sort(
        (a, b) => b.market_value - a.market_value
      ) as unknown as IDataSourceItem[];
  }
}
