import dayjs from "dayjs";

export const hkRate = 0.1281;
export function hkToUS(num: number) {
  return Number((num * hkRate).toFixed(2));
}
export function newTimeformat(): string {
  const now = dayjs();
  return now.format("YYYY-MM-DD HH:mm:ss");
}

export function changePercent(target: Record<string, number>, rate: number) {
  const map: Record<string, number> = {};
  Object.keys(target).map((symbol) => {
    map[symbol] = Number((target[symbol] * rate).toFixed(2));
  });
  return map;
}

export const symbolMap = {
  "01797": "01797(东方甄选)",
  "01810": "01810小米",
} as Record<string, string>;
