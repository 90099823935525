/* eslint-disable @typescript-eslint/naming-convention */

import { Tabs } from "antd";
import styles from "./index.module.scss";
import React, { useMemo } from "react";

import { Home } from "./pages/stock-page/views/home/home";
import { RiskTable } from "./pages/stock-page/views/risk/RiskTable";
import { ServicesProvider } from "./.frame/index";
import { Copilot } from "./pages/ai-page/views/copilot";

export const App: React.FC = () => {
  return (
    <ServicesProvider>
      <Tabs
        className={styles.root}
        defaultActiveKey={
          new URL(location.href).searchParams.get("page") || "Home"
        }
        items={[
          {
            key: "Home",
            label: "Home",
            children: <Home />,
          },
          {
            key: "RiskTable",
            label: "RiskTable",
            children: <RiskTable />,
          },
          {
            key: "Copilot",
            label: "Copilot",
            children: <Copilot />,
          },
        ]}
      />
    </ServicesProvider>
  );
};
