/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable require-await */
import OpenAI from "openai";

interface IGlobalConfig {
  apiKey: string;
}

class Completions {
  config: IGlobalConfig;
  constructor(config: IGlobalConfig) {
    this.config = config;
  }
  async create(param: OpenAI.Chat.ChatCompletionCreateParams) {
    const res = await fetch(
      "https://openai.shaoweihuang.com/v1/chat/completions",
      {
        headers: {
          accept: "application/json, text/event-stream",
          authorization: `Bearer ${this.config.apiKey}`,
          "content-type": "application/json",
          "sec-ch-ua":
            '"Chromium";v="124", "Google Chrome";v="124", "Not-A.Brand";v="99"',
          "sec-ch-ua-mobile": "?0",
          "sec-ch-ua-platform": '"macOS"',
        },
        body: JSON.stringify({
          // stream: true,
          temperature: 0.5,
          presence_penalty: 0,
          frequency_penalty: 0,
          top_p: 1,
          max_tokens: 4000,
          ...param,
        }),
        method: "POST",
      }
    );
    // const reader = res.body?.pipeThrough(new TextDecoderStream()).getReader();
    // if (!reader) {
    //   return;
    // }
    // const recursion = async () => {
    //   const result = await reader.read();
    //   console.log(result.value);
    //   if (!result.done) {
    //     recursion();
    //   }
    // };
    // recursion();
    const result = await res.json();
    return result;
  }
}
class Chat {
  config: IGlobalConfig;
  constructor(config: IGlobalConfig) {
    this.config = config;
  }

  get completions() {
    return new Completions(
      this.config
    ) as unknown as OpenAI.Chat.ChatCompletion;
  }
}
export class WebOpenAI {
  config: IGlobalConfig;
  constructor(config: IGlobalConfig) {
    this.config = config;
  }
  get chat() {
    return new Chat(this.config) as unknown as OpenAI.Chat;
  }
}
export const openai = new WebOpenAI({
  apiKey: localStorage.getItem("_openai_apiKey") || "",
});
