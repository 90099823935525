import React from "react";

import { createContext, useContext, useMemo } from "react";
import dataJson from "../data.json";

import { Container } from "inversify";
import { useStore as useZustandStore } from "zustand";

import {
  IServices,
  IViewModelService,
  IDataStore,
  IStoreTypes,
  IData,
  IAssets,
  IStocks,
  IRiskService,
} from "./types";

import { ViewModelService } from "./services/ViewModelService";
import { createDataStore } from "./services/DataStore";
import { Assets } from "../pages/stock-page/services/Assets";
import { Stocks } from "../pages/stock-page/services/Stocks";
import { RiskService } from "../pages/stock-page/services/RiskService";

// 1------------------------- provider
const ServicesContext = createContext<Container>({} as Container);
// 局部store context
const LocalStoreContext = createContext<unknown>(null);

export const ServicesProvider: React.FC<{ children: unknown }> = ({
  children,
}) => {
  const services = useMemo(() => {
    const container = new Container({
      defaultScope: "Singleton",
    });
    // 调试使用
    (window as unknown as { __debug: unknown }).__debug = container;
    container.bind(IViewModelService).to(ViewModelService);
    // store
    container.bind<IDataStore>(IDataStore).toConstantValue(createDataStore());
    container.bind<IData>(IData).toConstantValue(dataJson);
    container.bind(IAssets).to(Assets);
    container.bind(IStocks).to(Stocks);
    container.bind(IRiskService).to(RiskService);

    return container;
  }, []);

  return (
    <ServicesContext.Provider value={services}>
      {children}
    </ServicesContext.Provider>
  );
};

// 2-------------------------  services
/**
 * 消费服务
 */
export function useServices(): IServices {
  const container = useContext(ServicesContext);
  // 使用 get 按需使用
  return {
    get viewModelService() {
      return container.get<IViewModelService>(IViewModelService);
    },
    get assets() {
      return container.get<IAssets>(IAssets);
    },

    get stocks() {
      return container.get<IStocks>(IStocks);
    },

    get data() {
      return container.get<IData>(IData);
    },
    get riskService() {
      return container.get<IRiskService>(IRiskService);
    },
  };
}
// 3-------------------------  store
export { toDataStore } from "./services/toDataStore";

export function useStore<K extends keyof IStoreTypes, T = IStoreTypes[K]>(
  name: K,
  selector?: (store: IStoreTypes[K]) => T
): T {
  const container = useContext(ServicesContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useZustandStore(container.get(name) as any, selector as any);
}
