import { IDialogViewState } from "../components/dialog-view";

import type { ViewModelService } from "../services/ViewModelService";

import { IDataState, IDataStore } from "../services/DataStore";
import { Assets } from "../../pages/stock-page/services/Assets";
import { Stocks } from "../../pages/stock-page/services/Stocks";
import { IData } from "../../types/index";
import { RiskService } from "../../pages/stock-page/services/RiskService";

// 1. -------------------- 内部导出
// 视图模型
export type IViewModelService = InstanceType<typeof ViewModelService>;
export const IViewModelService = "IViewModelService";
export { IDataStore } from "../services/DataStore";
export type { IDataState } from "../services/DataStore";
export type { ToDataStoreInterface } from "../services/toDataStore";

// 2. -------------------- store导出
// ---- store 导出类型

export interface IStoreTypes {
  [IDataStore]: IDataState;
}

// 3. -------------------- services导出

export type IAssets = InstanceType<typeof Assets>;
export const IAssets = "IAssets";

export type IStocks = InstanceType<typeof Stocks>;
export const IStocks = "IStocks";

export type IRiskService = InstanceType<typeof RiskService>;
export const IRiskService = "IRiskService";

export interface IServices {
  ["viewModelService"]: IViewModelService;
  ["assets"]: IAssets;
  ["stocks"]: IStocks;
  ["data"]: IData;
  ["riskService"]: IRiskService;
}

// 4. -------------------- 业务类型导出
export * from "../../types/index";

//  视图模型实例
export interface IViewModelInstanceType {
  //
}

//  data模型实例
export interface IDataStateInstanceType {
  //
}
