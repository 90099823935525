import { Table, Tag } from "antd";
import React from "react";
import { useServices } from "../../../../.frame";
import { addMoneyMax, positionInitValue } from "../../services/RiskService";
import { newTimeformat } from "../../services/util";

export const RiskTable: React.FC<{}> = () => {
  const { riskService } = useServices();
  return (
    <div>
      <div style={{ paddingBottom: "8px" }}>
        <Tag>当前时间{newTimeformat()}</Tag>
        <Tag>头寸金额：{positionInitValue}</Tag>
        <Tag>补仓总金额：{addMoneyMax}</Tag>
      </div>
      <Table
        style={{ overflow: "auto" }}
        dataSource={riskService.dataSource()}
        columns={riskService.columns()}
        pagination={false}
      />
    </div>
  );
};
