import { inject, injectable } from "inversify";
import { IData, IDataSourceItem } from "../../../types";
import { hkToUS } from "./util";

@injectable()
export class Assets {
  private data: IData;
  constructor(@inject(IData) data: IData) {
    this.data = data;
  }
  /**
   * 股票资产总额
   */
  public getTotalMarketValue(): number {
    const total = this.data.positions.reduce(
      (last, current) =>
        last +
        (current.contract.market === "HK"
          ? hkToUS(current.market_value)
          : current.market_value),
      0
    );
    return Number(total.toFixed(0));
  }

  /**
   * 现金总额
   */
  public getAssets() {
    const { cash } = this.data.assets[0]._summary;
    return { cash };
  }

  /**
   * 股票占资产的百分比
   */

  public getMarketValuePercent(): string {
    const valueAll = this.getTotalMarketValue();
    const { cash } = this.getAssets();
    return `${((100 * valueAll) / (valueAll + cash)).toFixed(1)}%`;
  }

  /**
   * 持股总盈亏
   */
  public getAllUnrealized_pnl(dataSource: IDataSourceItem[]) {
    return dataSource
      .reduce((last, current) => last + current.unrealized_pnl, 0)
      .toFixed(0);
  }
}
