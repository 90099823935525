import { IViewModelInstanceType } from '../types';
import { injectable } from 'inversify';
@injectable()
export class ViewModelService {
  private viewModelInstances = {} as Record<string, any>;
  setViewModel(id: string, model: unknown) {
    this.viewModelInstances[id] = model;
  }
  getViewModel<K extends keyof IViewModelInstanceType>(id: K): IViewModelInstanceType[K] {
    return this.viewModelInstances[id]?.getState();
  }
  getViewStore<T>(id: string): T {
    return this.viewModelInstances[id] as T;
  }
}
