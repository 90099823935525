import { injectable } from "inversify";
interface ActionItem {
  title: string;
  rate: number;
  addMoney: number;
}
// 想办法其他账户
// ============================== config area
// 头寸股票价值
export const positionInitValue = 6;
export const addMoneyMax = 8; // 追加1.5

const actionList: ActionItem[] = [
  {
    title: "第1次下跌建仓",
    rate: -0.05,
    addMoney: 2,
  },
  {
    title: "第2次下跌建仓",
    rate: -0.05,
    addMoney: 2,
  },
  {
    title: "第3次下跌建仓",
    rate: -0.1,
    addMoney: 2,
  },
  {
    title: "第4次下跌补仓",
    rate: -0.1,
    addMoney: 0.5,
  },
  // -----------
  // {
  //   title: "第4次下跌补仓1",
  //   rate: -0.1,
  //   addMoney: 1,
  // },
];
// ==============================

/**
 * 补仓股票累计价值和累计亏损
 */
function getBlocksValueAndLoss(currentIndex: number) {
  // 每次补仓的股票，都经历了前面轮的下跌
  return actionList.slice(0, currentIndex + 1).reduce(
    (last, cur) => {
      // 累计投资
      const totalPercent = last.totalPercent * (1 + cur.rate);
      const invest = last.invest + cur.addMoney;
      // 累计价值
      const value = last.value * (1 + cur.rate) + cur.addMoney;
      const positionValue = last.positionValue * (1 + cur.rate);
      const totalStockValue = positionValue + value;
      const totalStockLoss = value - invest + positionValue - positionInitValue;

      return {
        totalPercent: totalPercent,
        invest,
        value,
        loss: value - invest,
        positionValue,
        positionLoss: positionValue - positionInitValue,
        totalStockValue: totalStockValue,
        // 亏损之和
        totalStockLoss: totalStockLoss,
        // 无亏损上涨
        noLossPercent: -(totalStockLoss / totalStockValue),
        noLossPrice:
          100 * totalPercent * (1 - totalStockLoss / totalStockValue),
      };
    },
    {
      // 累计下跌比例
      totalPercent: 1,
      positionValue: positionInitValue, // 头寸价值
      positionLoss: 0, // 头寸损失
      invest: 0,
      value: 0,
      loss: 0,
      totalStockValue: positionInitValue,
      totalStockLoss: 0,
      noLossPercent: 0,
      noLossPrice: 100,
    }
  );
}

@injectable()
export class RiskService {
  dataSource() {
    return actionList.map((item, index) => {
      const blocksValueAndLoss = getBlocksValueAndLoss(index);
      return {
        title: item.title,
        addMoney: item.addMoney,
        rate: `${item.rate * 100} %`,
        totalPercent: `${((blocksValueAndLoss.totalPercent - 1) * 100).toFixed(
          1
        )} %`,
        addBlocksValue: blocksValueAndLoss.value.toFixed(4),
        addBlocksLoss: blocksValueAndLoss.loss.toFixed(4),
        positionValue: blocksValueAndLoss.positionValue.toFixed(3),
        positionLoss: blocksValueAndLoss.positionLoss.toFixed(3),
        totalStockValue: blocksValueAndLoss.totalStockValue.toFixed(3),
        totalStockLoss: blocksValueAndLoss.totalStockLoss.toFixed(3),
        noLossPercent: `${(blocksValueAndLoss.noLossPercent * 100).toFixed(
          2
        )} %`,
        noLossPrice: blocksValueAndLoss.noLossPrice.toFixed(2),
      };
    });
  }
  columns() {
    return [
      {
        title: "操作标题",
        dataIndex: "title",
      },
      {
        title: "基于上次变化",
        dataIndex: "rate",
      },
      // ------
      {
        title: "累计变化",
        dataIndex: "totalPercent",
      },
      {
        title: "补仓金额",
        dataIndex: "addMoney",
      },
      {
        title: "补仓股票价值",
        dataIndex: "addBlocksValue",
      },
      {
        title: "补仓亏损",
        dataIndex: "addBlocksLoss",
      },
      {
        title: "头寸股票价值",
        dataIndex: "positionValue",
      },
      {
        title: "头寸股票亏损",
        dataIndex: "positionLoss",
      },
      {
        title: "股票总价值",
        dataIndex: "totalStockValue",
      },
      {
        title: "股票总亏损",
        dataIndex: "totalStockLoss",
      },
      {
        title: "回本比例",
        dataIndex: "noLossPercent",
      },
      {
        title: "回本价格",
        dataIndex: "noLossPrice",
      },
    ];
  }
}
