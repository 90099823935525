/* eslint-disable @typescript-eslint/naming-convention */
import { Card, Table, Tag, Input, Tabs } from "antd";
import styles from "./index.module.scss";
import React, { useState } from "react";

import { newTimeformat } from "../../services/util";
import { useServices } from "../../../../.frame";

export const Home: React.FC = () => {
  const { stocks, assets } = useServices();
  const [note, setNote] = useState(localStorage.getItem("__mynote__") || "");
  const dataSource = stocks.getDataSource();
  const myassets = assets.getAssets();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Tag>时间</Tag>
        <span className={styles.num}> {newTimeformat()}</span>
        <Tag>持股资产</Tag>
        <span className={styles.num}> {assets.getTotalMarketValue()}</span>
        <Tag>持股总盈亏</Tag>{" "}
        <span className={styles.num}>
          {assets.getAllUnrealized_pnl(dataSource)}
        </span>
        <Tag>现金资产</Tag> <span className={styles.num}> {myassets.cash}</span>
        <Tag>持股占比</Tag>
        <span className={styles.num}> {assets.getMarketValuePercent()}</span>
        <Tag>总资产</Tag>
        <span className={styles.num}>
          {Number(assets.getTotalMarketValue() + myassets.cash).toFixed(1)}
        </span>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <Card className={styles.card}>
            <h3>资产现状</h3>
            <Table
              style={{ overflow: "auto" }}
              dataSource={dataSource}
              columns={stocks.getColumns()}
              pagination={false}
            />
          </Card>
        </div>
        {/* <div className={styles.col2}>
            <p>笔记</p>
            <Input.TextArea
              rows={12}
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
                localStorage.setItem("__mynote__", e.target.value);
              }}
            />
          </div> */}
      </div>
    </div>
  );
};
